import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import { WithMyTheme } from '@keaze/web/theme';

interface ButtonNavProps extends WithMyTheme {
  $isNext?: boolean;
}

export const ButtonNav = styled(Button)<ButtonNavProps>`
  ${({
    theme: {
      palette,
      transitions: { easing, duration },
    },
    $isNext,
  }) => `
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 42px;
    height: 42px;
    border-radius: 50%;
    padding: 0;
    min-width: auto;
    z-index: 9;
    ${$isNext ? 'right: -24px' : 'left: -24px'};

    svg path {
      transition: ${duration.standard}ms ${easing.easeInOut};
      stroke: ${palette.primary.main};
    }

    &.MuiButton-root {
      &:hover {
        svg path {
          stroke: ${palette.secondary.main};
        }
      }
    }

    &.Mui-disabled {
      &:hover {
        svg path {
          stroke: ${palette.primary.main};
        }
      }
    }
  `}
`;
