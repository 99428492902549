import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import NextImage from 'next/image';
import NextLink from 'next/link';
import { useEmblaCarousel } from 'embla-carousel/react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core/styles';

import { FeaturedHousingProvider } from '@keaze/web/common/interfaces';
import {
  Carousel as UICarousel,
  Viewport,
  Slide,
  Container,
  Link,
  SlideInner,
} from './carousel.styles';
import { PrevButton, NextButton } from './buttons';

type Props = {
  data: FeaturedHousingProvider[];
};

type RenderSlide = (
  slide: FeaturedHousingProvider,
  index: number
) => JSX.Element;

const renderSlide: RenderSlide = ({ slug, logoUrl, name }, index) => (
  <Slide key={index}>
    <SlideInner>
      <Link>
        <NextLink
          key={index}
          href={`/featured-housing-providers/${encodeURIComponent(slug)}`}
          prefetch={false}
          passHref
        >
          <a />
        </NextLink>
      </Link>
      <NextImage
        src={logoUrl as string}
        alt={name}
        width={130}
        height={130}
        objectFit="contain"
        objectPosition="center center"
      />
    </SlideInner>
  </Slide>
);

export const Carousel: FC<Props> = memo(({ data }) => {
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [viewportRef, embla] = useEmblaCarousel({
    align: isLarge ? 'start' : 'center',
    slidesToScroll: 1,
    skipSnaps: false,
    loop: true,
    containScroll: 'trimSnaps',
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);

  const withControls = useMemo(() => isLarge && data.length > 4, [
    isLarge,
    data,
  ]);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;

    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on('select', onSelect);
  }, [embla, onSelect]);

  return (
    <UICarousel>
      <Viewport ref={viewportRef}>
        <Container>
          {data.filter(({ logoUrl }) => !!logoUrl).map(renderSlide)}
        </Container>
      </Viewport>
      {withControls && (
        <>
          <PrevButton onClick={scrollPrev} disabled={!prevBtnEnabled} />
          <NextButton onClick={scrollNext} disabled={!nextBtnEnabled} />
        </>
      )}
    </UICarousel>
  );
});
