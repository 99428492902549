import React, { FC, memo } from 'react';
import { ButtonProps } from '@material-ui/core/Button';

import { ButtonNav } from './buttons.styles';

const ChevronLeftIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4331 1L1.99811 12.435L13.4331 23.87"
      transform="translate(3,0)"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

const ChevronRightIcon = () => (
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4331 1L23.8681 12.435L12.4331 23.87"
      transform="translate(-3,0)"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export const PrevButton: FC<ButtonProps> = memo(({ disabled, onClick }) => (
  <ButtonNav disabled={disabled} onClick={onClick}>
    <ChevronLeftIcon />
  </ButtonNav>
));

export const NextButton: FC<ButtonProps> = memo(({ disabled, onClick }) => (
  <ButtonNav disabled={disabled} $isNext onClick={onClick}>
    <ChevronRightIcon />
  </ButtonNav>
));
