import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const Wrapper = styled.div`
  ${({ theme: { breakpoints } }) => `
    margin: 34px 0 79px;
    
    ${breakpoints.down('md')}{
      margin: 28px 0 40px;
    }

  `}
`;

export const Title = styled(Typography)`
  ${({ theme: { breakpoints } }) => `
    text-align: center;
    margin: 0 0 15px;

    ${breakpoints.up('md')}{
      margin: 0 0 30px;
    }
  `}
`;
