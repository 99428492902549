import React, { FC, memo } from 'react';

import { Wrapper, Title } from './featuredHousingProviders.styles';
import { Carousel } from './carousel';
import { FeaturedHousingProvidersProps } from './featuredHousingProviders.types';

export const FeaturedHousingProviders: FC<FeaturedHousingProvidersProps> = memo(
  ({ data }) => (
    <Wrapper>
      <Title variant="h2">Featured housing providers</Title>
      <Carousel data={data} />
    </Wrapper>
  )
);
