import styled from 'styled-components';
import Box from '@material-ui/core/Box';

export const Carousel = styled(Box)`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Viewport: any = styled(Box)`
  ${({ theme: { breakpoints } }) => `
    overflow: hidden;
  
    ${breakpoints.up('lg')} {
      width: 1150px;
    }
  `}
`;

export const Container = styled(Box)`
  ${({ theme: { breakpoints } }) => `
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin-left: -30px;

    ${breakpoints.up('sm')}{
      margin-left: -50px;
    }
  `}
`;

export const Slide = styled(Box)`
  ${({ theme: { breakpoints } }) => `
    position: relative;
    padding-left: 30px;
    flex: 0 0 auto;

    ${breakpoints.up('sm')}{
      padding-left: 50px;
    }
  `}
`;

export const Link = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  a {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export const SlideInner = styled(Box)`
  ${({ theme: { palette, breakpoints } }) => `
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 230px;
    height: 230px;
    background-color: ${palette.common.white};
    overflow: hidden;

    ${breakpoints.up('sm')}{
      width: 250px;
      height: 250px;
    }
  `}
`;
